import { useRouter } from 'next/router';
import { STATIC_PAGES } from '../utils/helpers/constants';

export const useShowFlexibleComponents = () => {
  const router = useRouter();
  const allowedRoutesForFlexiblePages = Object.values(STATIC_PAGES);

  const isShowFlexibleComponents = allowedRoutesForFlexiblePages.includes(
    router.asPath
  );
  return { isShowFlexibleComponents };
};
